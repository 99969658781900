/* eslint-disable react/jsx-props-no-spreading */
import './App.css'

import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Skeleton from './components/skeleton/skeleton.component'
import Toast from './components/toast/toast.component'
import routes from './config/routes.config'
import { Routes } from './enums/routes.enum'
import { ProtectedRouteProps } from './types/route.type'
import { useAuthorization } from './hooks/authorization.hook'
import { useSeo } from './hooks/seo.hook'
import useReferral from './hooks/ui/useReferral'
import { AuthFormProvider } from './modules/auth/auth.context'
import { PaidInvoiceProvider } from './modules/invoice/paidInvoice.context'
import PageNotFound from './pages/page-not-found/page-not-found.component'
import cookieManager from './managers/cookie.manager'

const Styles = styled.div`
  font-family: 'Work Sans', sans-serif;
`
function App() {
  useSeo()
  useReferral()
  useAuthorization()
  
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated:    !!cookieManager.get('auth'),
    authenticationPath: '/login',
  };

  return (
    <Styles>
      <Switch>
        <Redirect exact from={Routes.HOME} to={Routes.LOGIN} />
        <Route path={routes.map((r) => r.url)}>
          <AuthFormProvider>
            <PaidInvoiceProvider>
              <Suspense fallback={<Skeleton />}>
                {routes.map((R) => !R.isAuthRoute ? 
                  (
                    <Route exact path={R.url} key={R.url} {...R.props}>
                      <R.Component />
                    </Route>
                  )
                  :
                  R.isAuthRoute && defaultProtectedRouteProps.isAuthenticated ?
                  (
                    <Route 
                      exact
                      path={R.url}
                      key={R.url}
                      {...R.props}
                    >
                      <R.Component />
                    </Route>
                  )
                  :
                  <Route exact path={R.url}>
                    <PageNotFound />
                  </Route>
                )}
              </Suspense>
            </PaidInvoiceProvider>
          </AuthFormProvider>
        </Route>
        <Route path={'/'}>
          <PageNotFound />
        </Route>
      </Switch>
      <Toast />
    </Styles>
  )
}

export default App
